import { Box, Container, Stack, Text, Heading, SimpleGrid,  } from "@chakra-ui/react"
import { graphql, PageProps } from "gatsby"
import React from "react"
import SectionOne from "../components/About/SectionOne"
import SectionTwo from "../components/About/SectionTwo"
import Layout from "../components/Base/Layout"
import Disclaimer from "../components/Sections/Disclaimer"

const CustomerAgreement: React.FC<CAProps> = ({ data }) => {
    
    const {
        wpPage: {
            customerAgreement: {
                customerAgreementText
            }
        }
    } = data
    
    return (
        <Layout>
            <Box as="section" bg="white">
        <Container py={{ base: '16', md: '24' }} maxW="7xl">
            <Stack spacing={{ base: '12', md: '16' }}>
                <Stack spacing={{ base: '4', md: '5' }} >
                    <Stack spacing="3">
                        <Heading color="gray.800" fontSize="4xl">
                            Customer Agreement
                        </Heading>
                    </Stack>
                    <Text color="gray.500" fontSize="20px" dangerouslySetInnerHTML={{ __html: customerAgreementText }}></Text>
                </Stack>
              
            </Stack>
        </Container>
        </Box>
            <Disclaimer></Disclaimer>
        </Layout>
    )
}

type CAProps = PageProps & {
    data: {
        wpPage: {
            customerAgreement: {
                customerAgreementText: string
            }
        }
    }
}

export const query = graphql`
  {
    wpPage(slug: {eq: "customer-agreement"}) {
      id
      customerAgreement {
        customerAgreementText
      }
    }
  }
`


export default CustomerAgreement